import React from 'react';
import styled, { css } from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../../services/sizeHelper';
import bgImage from '../../images/panbox-familj.png';
import Container from '../../components/atoms/container';
import Spacer from '../../components/atoms/spacer';
import Typography from '../../components/atoms/typography';
import Button from '../../components/atoms/button';
import { IPaymentCompleted } from '../../pages/klar';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: ${FULL_PAGE_HEIGHT};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img<{ isMobile: boolean }>`
  margin-bottom: -19px;
  width: 100%;
  max-width: 500px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-bottom: -4%;
    `}
`;

const UpdatePaymentCompleted: React.FC<IPaymentCompleted> = ({
  isMobile,
  paymentNumber,
  onButtonClick
}) => (
  <Wrapper>
    <Spacer spacing={8} />
    <StyledContainer>
      <Typography color="textSecondary" variant="header" align="center">
        Tack! Dina kortuppgifter har uppdaterats
      </Typography>
      <Typography color="textSecondary" align="center">
        {`Betal-ID: ${paymentNumber}`}
      </Typography>
      <Spacer spacing={2} />
      <Button onClick={onButtonClick}>Till mina sidor</Button>
      <Spacer spacing={4} />
      <Image isMobile={isMobile} src={bgImage} alt="familj" />
    </StyledContainer>
  </Wrapper>
);

export default UpdatePaymentCompleted;
