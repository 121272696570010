import React from 'react';
import styled, { css } from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../../services/sizeHelper';
import bgImage from '../../images/panbox-familj.png';
import Container from '../../components/atoms/container';
import Spacer from '../../components/atoms/spacer';
import Typography from '../../components/atoms/typography';
import Button from '../../components/atoms/button';
import { IPaymentCompleted } from '../../pages/klar';
import { Col, Row } from 'react-grid-system';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: ${FULL_PAGE_HEIGHT};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img<{ isMobile: boolean }>`
  margin-bottom: -19px;
  width: 100%;
  max-width: 500px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-bottom: -4%;
    `}
`;

const OrderPaymentCompleted: React.FC<IPaymentCompleted> = ({
  isMobile,
  paymentNumber,
  onButtonClick
}) => (
  <Wrapper>
    <Spacer spacing={8} />
    <StyledContainer>
      <Typography color="textSecondary" variant="header" align="center">
        Tack för ditt köp
      </Typography>
      <Typography color="textSecondary" align="center">
        {`Betal-ID: ${paymentNumber}`}
      </Typography>
      <Spacer spacing={2} />
      <Row justify="center" align="center">
        <Col md={8}>
          <Typography color="textSecondary">
            Nu är du förberedd vid nästa virusutbrott och kan känna dig trygg om
            något skulle hända.
          </Typography>
          <Spacer />
          <Typography color="textSecondary">
            Gå till <b>mina sidor</b> och fyll i den adress du vill att din
            panBox ska skickas till om behovet uppstår.
          </Typography>
          <Spacer />
          <Typography color="textSecondary">
            På mina sidor kan du även se din beställning, dina uppgifter samt
            beställa fler panBox om det skulle behövas.
          </Typography>
          <Spacer />
          <Typography color="textSecondary">
            Du kan när som helst logga in på dina sidor via huvudmenyn. Du
            loggar enkelt in med BankID vilket gör att du inte behöver komma
            ihåg något lösenord.
          </Typography>
        </Col>
      </Row>
      <Spacer spacing={2} />
      <Button onClick={onButtonClick}>Till mina sidor</Button>
      <Spacer spacing={4} />
      <Image isMobile={isMobile} src={bgImage} alt="familj" />
    </StyledContainer>
  </Wrapper>
);

export default OrderPaymentCompleted;
