import React from 'react';
import Container from '../../components/atoms/container';
import Button from '../../components/atoms/button';
import Typography from '../../components/atoms/typography';
import Spacer from '../../components/atoms/spacer';
import styled from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../../services/sizeHelper';
import bgImage from '../../images/panbox-lastbil.png';
import { IPaymentCompleted } from '../../pages/klar';

const Wrapper = styled.div`
  min-height: ${FULL_PAGE_HEIGHT};
  background: url(${bgImage}) ${({ theme }) => theme.colors.sky} center
    bottom/contain no-repeat;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

const OrderHomePaymentCompleted: React.FC<IPaymentCompleted> = ({
  isMobile,
  paymentNumber,
  onButtonClick
}) => (
  <Wrapper>
    <Spacer spacing={isMobile ? 10 : 6} />
    <StyledContainer>
      <Card>
        <Typography color="primary" variant="header" align="center">
          panBox är på väg!
        </Typography>
        <Spacer spacing={2} />
        <Typography variant="h4" align="center">
          Tack för ditt köp!
        </Typography>
        <Spacer />
        <Typography align="center">{`Betal-ID: ${paymentNumber}`}</Typography>
        <Spacer spacing={4} />
        <Button onClick={onButtonClick}>Till mina sidor</Button>
      </Card>
    </StyledContainer>
  </Wrapper>
);

export default OrderHomePaymentCompleted;
