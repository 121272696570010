import { navigate } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import Routes from '../services/routes';
import isBrowser from '../utils/isBrowser';
import useFirebaseContext from '../hooks/useFirebaseContext';
import {
  getPaymentNumber,
  getPaymentType,
  PAYMENT_TYPE,
  resetPaymentDetails
} from '../utils/localStorage';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import OrderHomePaymentCompleted from '../components/templates/orderHomePaymentCompleted';
import OrderPaymentCompleted from '../components/templates/orderPaymentCompleted';
import { endSubscription } from '../api';
import UpdatePaymentCompleted from '../components/templates/updatePaymentCompleted';

export interface IPaymentCompleted {
  isMobile: boolean;
  onButtonClick: () => void;
  paymentNumber: string;
}

const WelcomePage = () => {
  const { user, fetchData, storageAmount, productAmount } =
    useFirebaseContext();
  const { IS_MOBILE } = useScreenClassHelper();

  const [paymentNumber, setPaymentNumber] = useState<string>('');
  const [payType, setPaymentType] = useState<PAYMENT_TYPE>();
  const [hasBegun, setHasBegun] = useState(false);

  useEffect(() => {
    if (!user && isBrowser()) {
      window.location.replace(Routes.LANDING_PAGE);
    }
  }, [user]);

  useEffect(() => {
    const sendEmail = async () => {
      setHasBegun(true);
      const paymentNumber = getPaymentNumber();
      const paymentType = getPaymentType();

      if (paymentNumber !== null && typeof paymentType !== 'undefined') {
        setPaymentNumber(paymentNumber);
        setPaymentType(paymentType);
      }
      if (paymentType === PAYMENT_TYPE.END_SUBSCRIPTION && user?.ssn) {
        await endSubscription(user.ssn);
      }

      await fetchData();
      resetPaymentDetails();
    };
    if (user && !hasBegun && fetchData) {
      sendEmail();
    }
  }, [user, storageAmount, productAmount, hasBegun]);

  const handleButtonClick = () => {
    navigate(Routes.DASHBOARD);
  };

  const Component = useMemo(() => {
    switch (payType) {
      case PAYMENT_TYPE.DEFAULT_ORDER:
      case PAYMENT_TYPE.DEFAULT_ORDER_NO_STORAGE:
        return OrderPaymentCompleted;
      case PAYMENT_TYPE.ORDER_HOME:
      case PAYMENT_TYPE.ORDER_HOME_RESTOCK:
      case PAYMENT_TYPE.END_SUBSCRIPTION:
        return OrderHomePaymentCompleted;
      default:
        return UpdatePaymentCompleted;
    }
  }, [payType]);

  return (
    <Component
      isMobile={IS_MOBILE}
      onButtonClick={handleButtonClick}
      paymentNumber={paymentNumber}
    />
  );
};

export default WelcomePage;
